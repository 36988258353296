<template>
  <div class="services">
    <div class="title-dashed">{{ title }}</div>
    <div class="services-items">
      <div
        v-for="(item, idx) in services"
        :key="`item-${idx}`"
        class="materials-item"
      >
        <div class="adaptive-img imgWrapper">
          <NuxtLink
            class="link"
            :to="item.link"
          >
            <NuxtImg
              fit="crop"
              :width="idx !== lastItemIdx ? 503 : 974"
              height="222"
              loading="lazy"
              :alt="`${item?.title} в Тюмени`"
              :src="String(item.image)"
              class="adaptive-img"
            />
          </NuxtLink>
        </div>
        <NuxtLink
          :to="item.link"
          class="services-link"
        >
          <div>{{ item.title }}</div>
          <img
            src="~/assets/img/svg/common/arrow-right-green.svg"
            alt="arrow"
          >
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ServicesItem } from '~/cms/blocks/RmmServicesMaterialsListBlock.vue';

const { title, services } = defineProps<{
  title: string;
  services: ServicesItem[];
}>();

const lastItemIdx = computed(() => services.length - 1);
</script>

<style scoped lang="scss">
.services {
  width: 80%;
}

.services-items {
  display: flex;
  margin-left: -30px;
  margin-top: -40px;
  flex-wrap: wrap;

  & > div {
    width: calc(100% / 2 - 30px);
    margin-left: 30px;
    margin-top: 40px;
  }

  & > div:last-child {
    width: calc(100% / 1 - 30px);
  }
}

.imgWrapper {
  position: relative;
  height: 222px;
  margin-bottom: 18px;
  transition: box-shadow .3s linear;
  border-radius: 12px;

  &:hover {
    box-shadow: 0 0 31px 11px rgb(34 60 80 / 14%);
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 12px;
    object-fit: cover;
  }
}

.services-link {
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  transition: color .3s linear;

  & > div {
    font-size: 28px;
    line-height: 130%; /* 36.4px */
    letter-spacing: -0.7px;
  }

  &:hover {
    color: var(--text-primary);
  }
}

@media (max-width: 1024px) {
  .services {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .services-items {
    margin-top: -30px;

    & > div {
      width: calc(100% / 1 - 30px);
      margin-top: 30px;
    }
  }

  .imgWrapper {
    height: 145px;
  }

  .services-link {
    gap: 6px;

    & > div {
      font-size: 22px;
      line-height: 80%;
    }
  }
}
</style>
